import transaviaLogo from '/images/logos/transavia-logo.raw.svg'
import martinAirLogo from '/images/logos/martinair-logo.raw.svg'
import cygnificLogo from '/images/logos/cygnific-logo.raw.svg'
import healthServicesLogo from '/images/logos/klm-health-services-logo.raw.svg'
import flightAcademyLogo from '/images/logos/klm-flight-academy-logo.raw.svg'
import regionalJetCenterLogo from '/images/logos/regional-jet-center-logo.raw.svg'
import epcorLogo from '/images/logos/epcor-logo.raw.svg'

export function useGetCompanyInfo(company) {
  if (!company) return null

  const { label } = company
  const companyInfo = getInfoBasedOnLabel(label)

  return companyInfo
}

function getInfoBasedOnLabel(label) {
  switch (label) {
    case 'Transavia':
      return {
        logo: transaviaLogo,
        label: 'Transavia',
      }
    case 'Martinair':
      return {
        logo: martinAirLogo,
        label: 'Martinair',
      }
    case 'KLM-Health-Services':
      return {
        logo: healthServicesLogo,
        label: 'KLM-Health-Services',
      }
    case 'KLM-Flight-Academy':
      return {
        logo: flightAcademyLogo,
        label: 'KLM-Flight-Academy',
      }
    case 'Cygnific':
      return {
        logo: cygnificLogo,
        label: 'Cygnific',
      }
    case 'Regional-Jet-Center':
      return {
        logo: regionalJetCenterLogo,
        label: 'Regional-Jet-Center',
      }
    case 'Epcor':
      return {
        logo: epcorLogo,
        label: 'Epcor',
      }
    default:
      return null
  }
}
